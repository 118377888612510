<template>
  <div class="register-page">
    <Navigation isRegistration />
    <RegistrationForm v-if="!userRegistered" />
    <RegistrationSuccess v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Navigation from '@/components/Navigation.vue'
import RegistrationForm from '@/components/RegistrationForm.vue'
import RegistrationSuccess from '@/components/RegistrationSuccess.vue'

export default {
  components: {
    Navigation,
    RegistrationForm,
    RegistrationSuccess,
  },
  computed: {
    ...mapGetters(['userRegistered']),
  },
}
</script>

<style lang="scss">
.register-page {
  padding-top: $navbar-height;
}
</style>