<template>
  <div class="register">
    <div class="register__column">
      <h1 class="register__column__header">{{ $t('register.form.header') }}</h1>
      <p class="register__column__title">
        {{ $t('register.form.title-personal') }}
      </p>
      <el-form label-position="top" :model="form" :rules="rules">
        <el-form-item :label="$t('label.salutation')">
          <el-select
            v-model="form.salutation"
            :placeholder="$t('label.salutation')"
          >
            <el-option
              v-for="item in [
                { label: $t('dropdown.salutation.mr'), value: $t('dropdown.salutation.mr') },
                { label: $t('dropdown.salutation.ms'), value: $t('dropdown.salutation.ms') },
              ]"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('label.firstname')" prop="firstName">
          <el-input
            v-model="form.firstName"
            :placeholder="$t('label.firstname')"
          />
        </el-form-item>
        <el-form-item :label="$t('label.lastname')" prop="lastName">
          <el-input
            v-model="form.lastName"
            :placeholder="$t('label.lastname')"
          />
        </el-form-item>
        <el-form-item
          :label="$t('label.email')"
          prop="email"
          @change="validateEmail"
          :class="{ register__column__error: error.email }"
        >
          <el-input v-model="form.email" :placeholder="$t('label.email')" />
          <p v-if="error.email" class="register__column__error__text">
            {{ $t('error.email') }}
          </p>
        </el-form-item>
        <p class="register__column__title">
          {{ $t('register.form.title-role') }}<span class="required">*</span>
        </p>
        <el-radio-group v-model="form.usergroup">
          <el-radio v-for="role in userRoles" :key="role.key" :label="role.key">
            {{ role.label[this.currentLanguage] }}
          </el-radio>
        </el-radio-group>
        <template v-for="(field, index) in selectedRole?.fields">
          <el-form-item
            :key="index"
            v-if="field.type === 'text'"
            :label="field.label[this.currentLanguage]"
            :prop="field.key"
            class="register__column__conditional"
          >
            <el-input
              v-model="form[field.key]"
              :placeholder="field.placeholder[this.currentLanguage]"
              :type="field.type"
            />
          </el-form-item>
          <el-form-item
            :key="index"
            v-if="field.type === 'select'"
            :label="field.label[this.currentLanguage]"
            :prop="field.key"
            class="register__column__conditional"
          >
            <el-select
              v-model="form[field.key]"
              :placeholder="field.placeholder[this.currentLanguage]"
            >
              <el-option
                v-for="option in field.options.map((option) => ({
                  label: option.label[this.currentLanguage],
                  value: option.value,
                }))"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </el-select>
          </el-form-item>
        </template>
        <hr />
        <el-checkbox class="checkbox-form" v-model="form.accept">
          <i18n-t keypath="register.form.acceptance" for="register.form.terms">
            <a
              class="checkbox-form__link"
              :href="termsConditionsLink"
              target="_blank"
              >{{ $t('register.form.terms') }}
            </a>
          </i18n-t>
          <span class="required">*</span>
        </el-checkbox>
        <vue-recaptcha
          :siteKey="recaptchaSiteKey"
          :hl="currentLanguage"
          @verify="form.recaptcha = true"
          @expire="recaptchaExpired"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </el-form>
      <p class="register__column__message" v-if="userRegisterError">
        {{
          userRegisterError === 'Email already exists.'
            ? $t('error.register-existing')
            : $t('error.register-approval')
        }}
      </p>
      <Button
        class="button--right"
        :label="$t('button.register')"
        :disabled="isDisabled"
        @click="register"
        ><IconArrowRight />
      </Button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import vueRecaptcha from 'vue3-recaptcha2'

import Button from '@/components/Button.vue'
import IconArrowRight from '@/components/icons/IconArrowRight'

export default {
  components: {
    vueRecaptcha,
    Button,
    IconArrowRight,
  },
  staticRules: {
    firstName: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    lastName: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    email: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
    usergroup: [
      {
        required: true,
        message: 'Pflichtfeld',
        trigger: 'blur',
      },
    ],
  },
  data() {
    return {
      form: {
        salutation: '',
        firstName: '',
        lastName: '',
        email: '',
        usergroup: '',
        accept: false,
        recaptcha: false,
      },
      rules: this.$options.staticRules,
      error: {
        email: false,
      },
    }
  },
  mounted() {
    this.$store.dispatch('getUserRoles')

    if (this.userRegisterError) this.$store.commit('setUserRegisterError', '')
  },
  computed: {
    ...mapGetters(['currentLanguage', 'userRoles', 'userRegisterError', 'termsConditionsLink']),
    selectedRole() {
      return this.userRoles?.find((role) => role.key === this.form.usergroup)
    },
    finalForm() {
      // without dynamic fields from other roles
      const finalForm = {}
      const finalKeys = Object.keys(this.rules)
      finalKeys.forEach((key) => {
        finalForm[key] = this.form[key]
      })

      return this.selectedRole ? finalForm : null
    },
    isDisabled() {
      const hasEmptyFields = this.finalForm
        ? !Object.values(this.finalForm).every((field) => field)
        : true

      return (
        hasEmptyFields ||
        !this.form.accept ||
        !this.form.recaptcha ||
        this.error.email
      )
    },
    recaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITE_KEY
    },
  },
  watch: {
    selectedRole() {
      this.addDynamicFieldsToRules()
    },
    $data: {
      handler() {
        if (this.userRegisterError)
          this.$store.commit('setUserRegisterError', '')
      },
      deep: true,
    },
  },
  methods: {
    validateEmail() {
      if (
        (this.form.email && !this.form.email.includes('@')) ||
        !this.form.email.includes('.')
      ) {
        this.error.email = true
      } else {
        this.error.email = false
      }
    },
    register() {
      this.$store.dispatch('userRegister', {
        salutation: this.form.salutation,
        ...this.finalForm,
      })
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset()
    },
    addDynamicFieldsToRules() {
      const newRules = { ...this.$options.staticRules }
      const fieldKeys = this.selectedRole?.fields.map((field) => field.key)
      fieldKeys.forEach((key) => {
        newRules[key] = [
          {
            required: true,
            message: 'Pflichtfeld',
            trigger: 'blur',
          },
        ]
      })
      this.rules = newRules
    },
  },
}
</script>

<style lang="scss" scoped>
.register {
  @include flex-center-align;
  flex-direction: column;

  &__column {
    display: flex;
    flex-direction: column;
    padding: 40px 20px;
    max-width: 650px;
    width: 100%;

    @include mq($from: mobile) {
      padding: 80px 20px;
    }

    &__header {
      margin-bottom: 20px;

      &::after {
        content: '';
        background-color: $color-primary;
        display: block;
        margin-top: 7px;
        height: 4px;
        width: 80px;
      }
    }

    &__title {
      font-stretch: condensed;
      font-size: 28px;
      font-weight: $font-weight-medium;
      margin-top: 60px;
      margin-bottom: 40px;

      span {
        color: $color-error; // title required asterix
      }

      &::after {
        content: '';
        background-color: $color-black-10;
        display: block;
        margin-top: 7px;
        height: 1px;
        width: 100%;
      }

      .elf & {
        font-size: 20px;
        font-stretch: normal;
        font-weight: $font-weight-bold;
        line-height: 1.4;
        text-transform: uppercase;
      }
    }

    &__error {
      input {
        border-color: $color-error;
      }

      &__text {
        color: $color-error;
        font-size: 14px;
        line-height: 14px;
        padding-top: 4px;
      }
    }

    hr {
      margin-bottom: 40px;
    }

    .el-select {
      width: 220px;
    }

    .el-radio-group {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      margin-bottom: 16px;

      @include mq($from: mobile, $until: tablet) {
        grid-template-columns: repeat(2, 1fr);
      }

      @include mq($from: tablet) {
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &__conditional {
      margin-bottom: 40px;
    }

    .checkbox-form {
      margin-bottom: 24px;

      &__link {
        color: $color-primary;
        text-decoration: underline;
      }
    }

    &__message {
      color: $color-error;
      margin-top: 20px;
    }

    .button--right {
      margin-top: 24px;
      align-self: flex-end;
    }
  }
}
</style>
