<template>
  <div class="register">
    <div class="register__header">
      <h1 class="register__header__text">
        {{ $t('register.success.header-success') }}<br />{{ $t('register.success.header-next') }}:
      </h1>
    </div>
    <div class="register__next-steps">
      <div class="register__steps">
        <h5 class="register__steps__number">{{ $t('register.success.step-1') }} —</h5>
        <h5 class="register__steps__title">{{ $t('register.success.step-1-title') }}</h5>
        <p class="register__steps__description">
          <i18n-t keypath="register.success.step-1-description" for="register.success.step-1-span">
            <span>{{ $t('register.success.step-1-span') }}</span>
          </i18n-t>
        </p>
      </div>
      <div class="register__steps">
        <h5 class="register__steps__number">{{ $t('register.success.step-2') }} —</h5>
        <h5 class="register__steps__title">{{ $t('register.success.step-2-title') }}</h5>
        <p class="register__steps__description">
          <i18n-t keypath="register.success.step-2-description" for="register.success.step-2-link">
            <a class="register__steps__link" :href="'mailto:' + contactLink" target="_blank">
              {{ $t('register.success.step-2-link') }}
            </a>
          </i18n-t>
        </p>
      </div>
      <div class="register__steps">
        <h5 class="register__steps__number">{{ $t('register.success.step-3') }} —</h5>
        <h5 class="register__steps__title">{{ $t('register.success.step-3-title') }}</h5>
        <p class="register__steps__description">
          {{ $t('register.success.step-3-description') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters(['contactLink']),
  }
}
</script>

<style lang="scss" scoped>
.register {
  @include flex-center-align;
  flex-direction: column;

  &__header {
    padding: 0 20px;

    &__text {
      margin: 40px 0 60px;

      @include mq($from: mobile) {
        margin: 80px 0 130px;
      }

      &::after {
        content: '';
        background-color: $color-primary;
        display: block;
        margin-top: 7px;
        height: 4px;
        width: 80px;
      }
    }
  }

  &__next-steps {
    @include flex-center-justify;
    gap: 10px;
    margin: 0 20px 20px 20px;

    @include mq($until: desktop) {
      flex-wrap: wrap;
    }
  }

  &__steps {
    background-color: $color-grey-200;
    padding: 30px;
    max-width: 430px;
    width: 100%;

    &__title {
      text-transform: uppercase;
    }

    &__description {
      margin-top: 28px;
    }

    span {
      font-weight: $font-weight-bold;
    }

    &__link {
      color: $color-primary;
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
</style>
